/* body {
    font-family: Nunito, sans-serif;
    animation: fadeIn 1s ease-in 1;
  
    animation: scaleUp 3s ease-in 1;

  } */
  
  .aboutbody{
    
    background-image: url("../public/assets/background.jpeg");
   z-index: -999;
   /* height: 70vh; */
  }
  .responsive-container-block {
    min-height: 75px;
    height: fit-content;
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    justify-content: flex-start;
  }
  
  a {
    text-decoration-line: none;
    text-decoration-thickness: initial;
    text-decoration-style: initial;
    text-decoration-color: initial;
  }
  
  .text-blk {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    line-height: 25px;
  }
  
  .responsive-container-block.bigContainer {
    padding-top: 10px;
    padding-right: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 50px 10px 50px;
  }
  
  .mainImg {
    color: black;
    width: 100%;
    height: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  
  }
  
  .text-blk.headingText {
    font-size: 22px;
    font-weight: 800;
    line-height: 30px;
    color: rgb(98, 135, 255);
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
  }
  
  .allText {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    width: 40%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .text-blk.subHeadingText {
    color: rgb(102, 102, 102);
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  .text-blk.description {
    font-size: 18px;
    line-height: 26px;
    color: rgb(102, 102, 102);
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
    font-weight: 400;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  .explore {
    font-size: 16px;
    line-height: 28px;
    color: rgb(102, 102, 102);
    border-top-width: 2px;
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(102, 102, 102);
    border-right-color: rgb(102, 102, 102);
    border-bottom-color: rgb(102, 102, 102);
    border-left-color: rgb(102, 102, 102);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    cursor: pointer;
    background-color: white;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 8px;
    padding-right: 40px;
    padding-bottom: 8px;
    padding-left: 40px;
  }
  
  .explore:hover {
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(176, 98, 255);
    color: white;
    border-top-width: initial;
    border-right-width: initial;
    border-bottom-width: initial;
    border-left-width: initial;
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
    border-top-color: initial;
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
  }
  
  .responsive-container-block.Container {
    margin-top: 80px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    max-width: 1320px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
  }
  
  .responsive-container-block.Container.bottomContainer {
    flex-direction: row-reverse;
    margin-top: 10px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
    position: static;
  }
  
  .allText.aboveText {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 40px;
  }
  
  .allText.bottomText {
    margin-top: -100px;
    margin-right: 70px;
    margin-bottom: 0px;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 0px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  
  .purpleBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 430px;
    background-color: rgb(98, 135, 255);
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    bottom: -40px;
    left: -8%;

  }
  
  .purpleText {
    font-size: 18px;
    line-height: 26px;
    color: white;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  
  .ultimateImg {
    width: 50%;
    position: relative;
  }
  
  @media (max-width: 1024px) {
    .responsive-container-block.Container {
      max-width: 850px;
    }
  
    .mainImg {
      width: 55%;
      height: auto;
    }
  
    .allText {
      width: 40%;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 20px;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
    }
  
    .responsive-container-block.Container.bottomContainer {
      margin-top: 10px;
      margin-right: auto;
      margin-bottom: 50px;
      margin-left: auto;
    }
  
    .responsive-container-block.Container {
      max-width: 830px;
    }
  
    .allText.aboveText {
      margin-top: 30px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 40px;
    }
  
    .allText.bottomText {
      margin-top: 30px;
      margin-right: 40px;
      margin-bottom: 0px;
      margin-left: 0px;
      text-align: left;
    }
  
    .text-blk.headingText {
      text-align: center;
    }
  
    .allText.aboveText {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .text-blk.subHeadingText {
      text-align: left;
      font-size: 26px;
      line-height: 32px;
    }
  
    .text-blk.description {
      text-align: left;
      line-height: 24px;
    }
  
    .explore {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 30px;
      padding-bottom: 10px;
      padding-left: 30px;
    }
  
    .responsive-container-block.Container {
      justify-content: space-evenly;
    }
  
    .purpleBox {
      bottom: 10%;
    }
  
    .responsive-container-block.Container.bottomContainer {
      padding-top: 10px;
      padding-right: 0px;
      padding-bottom: 10px;
      padding-left: 0px;
      max-width: 930px;
    }
  
    .allText.bottomText {
      width: 40%;
    }
  
    .purpleBox {
      bottom: auto;
      left: -10%;
      top: 70%;
    }
  
    .mainImg {
      width: 100%;
    }
  
    .text-blk.headingText {
      text-align: left;
    }
  }

   @media (min-width: 767px) and (max-width:1024px){
    /* .allText.bottomText{
      margin-top: 10vh;
    } */

     .responsive-container-block.Container.bottomContainer{
      margin-top: -5vh;
      overflow-y: hidden;
      
    } 

    .purpleBox{
      margin-top: -15vh;
    } 

    .text-blk.text-blk.headingText{
      margin-top: 25vh;

    }

 
  }  



  @media (min-width: 540px) and (max-width:766px){
    .text-blk.text-blk.headingText{
      margin-top: 35vh;
    }

  }


  
  @media (max-width: 768px) {
    .allText {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
    }
  
    .responsive-container-block.Container {
      flex-direction: column;
      height: auto;
    }
  
    .text-blk.headingText {
      text-align: center;
    }
  
    .text-blk.subHeadingText {
      text-align: center;
      font-size: 24px;
    }
  
    .text-blk.description {
      text-align: center;
      font-size: 18px;
    }
  
    .allText {
      margin-top: 40px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .allText.aboveText {
      margin-top: 40px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .responsive-container-block.Container {
      margin-top: 80px;
      margin-right: auto;
      margin-bottom: 50px;
      margin-left: auto;
    }
  
    .responsive-container-block.Container.bottomContainer {
      margin-top: 50px;
      margin-right: auto;
      margin-bottom: 50px;
      margin-left: auto;
    }
  
    .allText.bottomText {
      margin-top: 40px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .mainImg {
      width: 100%;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: -70px;
      margin-left: 0px;
    }
  
    .responsive-container-block.Container.bottomContainer {
      flex-direction: column;
    }
  
    .ultimateImg {
      width: 100%;
    }
  
    .purpleBox {
      position: static;
    }
  
    .allText.bottomText {
      width: 100%;
      align-items: flex-start;
    }
  
    .text-blk.headingText {
      text-align: left;
    }
  
    .text-blk.subHeadingText {
      text-align: left;
    }
  
    .text-blk.description {
      text-align: left;
    }
  
    .ultimateImg {
      position: static;
    }
  
    .mainImg {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .ultimateImg {
      position: relative;
    }
  
    .purpleBox {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      position: absolute;
      left: 0px;
      top: 80%;
    }
  
    .allText.bottomText {
      margin-top: 100px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  }
  
  @media (max-width: 500px) {
    .responsive-container-block.Container {
      padding-top: 10px;
      padding-right: 0px;
      padding-bottom: 10px;
      padding-left: 0px;
      width: 100%;
      max-width: 100%;
    }
  
    .mainImg {
      width: 100%;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 25px;
      padding-bottom: 10px;
      padding-left: 25px;
    }
  
    .text-blk.subHeadingText {
      font-size: 24px;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      line-height: 28px;
    }
  
    .text-blk.description {
      font-size: 16px;
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      line-height: 22px;
    }
  
    .allText {
      padding-top: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      width: 100%;
    }
  
    .allText.bottomText {
      margin-top: 50px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      padding: 0 0 0 0;
      margin: 30px 0 0 0;
    }
  
    .ultimateImg {
      position: static;
    }
  
    .purpleBox {
      position: static;
    }
  
    .stars {
      width: 55%;
    }
  
    .allText.bottomText {
      margin-top: 75px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .responsive-container-block.bigContainer {
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 10px;
      padding-left: 20px;
    }
  
    .purpleText {
      font-size: 16px;
      line-height: 22px;
    }
  
    .explore {
      padding: 6px 35px 6px 35px;
      font-size: 15px;
    }
  }


  /* RESPONSIVE*/

  /* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&amp;display=swap'); */

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
}

.wk-desk-1 {
  width: 8.333333%;
}

.wk-desk-2 {
  width: 16.666667%;
}

.wk-desk-3 {
  width: 25%;
}

.wk-desk-4 {
  width: 33.333333%;
}

.wk-desk-5 {
  width: 41.666667%;
}

.wk-desk-6 {
  width: 50%;
}

.wk-desk-7 {
  width: 58.333333%;
}

.wk-desk-8 {
  width: 66.666667%;
}

.wk-desk-9 {
  width: 75%;
}

.wk-desk-10 {
  width: 83.333333%;
}

.wk-desk-11 {
  width: 91.666667%;
}

.wk-desk-12 {
  width: 100%;
}

@media (max-width: 1024px) {
  .wk-ipadp-1 {
    width: 8.333333%;
  }

  .wk-ipadp-2 {
    width: 16.666667%;
  }

  .wk-ipadp-3 {
    width: 25%;
  }

  .wk-ipadp-4 {
    width: 33.333333%;
  }

  .wk-ipadp-5 {
    width: 41.666667%;
  }

  .wk-ipadp-6 {
    width: 50%;
  }

  .wk-ipadp-7 {
    width: 58.333333%;
  }

  .wk-ipadp-8 {
    width: 66.666667%;
  }

  .wk-ipadp-9 {
    width: 75%;
  }

  .wk-ipadp-10 {
    width: 83.333333%;
  }

  .wk-ipadp-11 {
    width: 91.666667%;
  }

  .wk-ipadp-12 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .wk-tab-1 {
    width: 8.333333%;
  }

  .wk-tab-2 {
    width: 16.666667%;
  }

  .wk-tab-3 {
    width: 25%;
  }

  .wk-tab-4 {
    width: 33.333333%;
  }

  .wk-tab-5 {
    width: 41.666667%;
  }

  .wk-tab-6 {
    width: 50%;
  }

  .wk-tab-7 {
    width: 58.333333%;
  }

  .wk-tab-8 {
    width: 66.666667%;
  }

  .wk-tab-9 {
    width: 75%;
  }

  .wk-tab-10 {
    width: 83.333333%;
  }

  .wk-tab-11 {
    width: 91.666667%;
  }

  .wk-tab-12 {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .wk-mobile-1 {
    width: 8.333333%;
  }

  .wk-mobile-2 {
    width: 16.666667%;
  }

  .wk-mobile-3 {
    width: 25%;
  }

  .wk-mobile-4 {
    width: 33.333333%;
  }

  .wk-mobile-5 {
    width: 41.666667%;
  }

  .wk-mobile-6 {
    width: 50%;
  }

  .wk-mobile-7 {
    width: 58.333333%;
  }

  .wk-mobile-8 {
    width: 66.666667%;
  }

  .wk-mobile-9 {
    width: 75%;
  }

  .wk-mobile-10 {
    width: 83.333333%;
  }

  .wk-mobile-11 {
    width: 91.666667%;
  }

  .wk-mobile-12 {
    width: 100%;
  }
}

/* body{
  animation: fadeIn 2s ease-in;
}

.allText.bottomText{
  animation: slideIn 2s ease-in;
  
} */


@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}


@keyframes slideIn {
  from {
      opacity: 0;
      transform: translateX(-40%);
  }
  to {
      opacity: 1;
      transform: translateX(0%);
  }
}

@keyframes scaleUp {
  from {
      opacity: 0;
      transform: scale(0.5);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}

/* gilroy font  */


@font-face{
  font-family: gilroy-bold;
  src: url(../public/Gilroy-Bold.ttf);
}


@font-face{
  font-family: gilroy-semibold;
  src: url(../public/Gilroy-SemiBold.ttf);
}


@font-face{
  font-family: gilroy-regular;
  src: url(../public/Gilroy-Regular.ttf);
}

@font-face{
  font-family: gilroy-extrabold;
  src: url(../public/Gilroy-ExtraBold.ttf);
}



.text-blk.headingText{
  font-family: gilroy-extrabold;
  font-size: 30px;
}


.text-blk.subHeadingText{
  font-family: gilroy-semibold;
  font-size: 24px;
 

}

.text-blk.description{
  font-family: gilroy-regular;
  font-weight: 600;
  font-size: 18px;

}

.purpleText{
  font-family: gilroy-regular;
  font-weight: 600;

}

.explore{
  font-family: gilroy-regular;
  font-weight: 600;
}




