#container3{
   
    width: 100%;
    height: 100vh;
 
    background: 
    #F9FBFE
    /* url("https://i.pinimg.com/originals/0c/28/08/0c28087b5cedf7276ee6c8d81e28d328.gif") */
    no-repeat center center;
    z-index: 9999;
    background-repeat: no-repeat;
}

.preloader{
    position: absolute;
    top: 28vh;
    left: 35vw;
    width: 30vw;
    height: 50vh;
}

@media screen  and  (max-width:769px){
    .preloader{
        position: absolute;
        top: 16.5vh;
        left: 4vw;
        width: 100vw;
        height: 50vh;
    }

    
}
