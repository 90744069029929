#maincentre{
  background-color: white;

}


/* 
*{
    box-sizing: border-box;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
 */

.cardsall{
 
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: nowrap;
    gap: 5rem;
    margin-top: 60px;
}
.card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  border-radius: 24px;
  line-height: 1.6;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.card1 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;

  border-radius: 24px;
  line-height: 1.6;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}


.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 36px;
  border-radius: 22px;
  color: #ffffff;
  overflow: hidden;
  background: rgb(98, 135, 255);
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content::before {
  position: absolute;
  content: "";
  top: -4%;
  left: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%);
  background: #ced8ff;
  z-index: -1;
  transform-origin: bottom;

  border-radius: inherit;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content::after {
  position: absolute;
  content: "";
  top: -8%;
  left: 50%;
  width: 80%;
  height: 80%;
  transform: translate(-50%);
  background: #e7ecff;
  z-index: -2;
  transform-origin: bottom;
  border-radius: inherit;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content svg {
  width: 48px;
  height: 48px;
}

.content .para {
  z-index: 1;
  opacity: 1;
  font-size: 18px;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content .link {
  z-index: 1;
  color: #fea000;
  text-decoration: none;
  font-family: inherit;
  font-size: 16px;
  transition: all 0.48s cubic-bezier(0.23, 1, 0.32, 1);
}

.content .link:hover {
  text-decoration: underline;
}

.card:hover {
  transform: translate(0px, -16px);
}

.card:hover .content::before {
  rotate: -8deg;
  top: 0;
  width: 100%;
  height: 100%;
}

.card:hover .content::after {
  rotate: 8deg;
  top: 0;
  width: 100%;
  height: 100%;
}

.card1:hover {
  transform: translate(0px, -16px);
}

.card1:hover .content::before {
  rotate: -8deg;
  top: 0;
  width: 100%;
  height: 100%;
}

.card1:hover .content::after {
  rotate: 8deg;
  top: 0;
  width: 100%;
  height: 100%;
}


button1 {
  font-family: inherit;
  font-size: 20px;
  background: #212121;
  color: white;
  fill: rgb(155, 153, 153);
  padding: 0.7em 1em;
  padding-left: 0.9em;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 15px;
  font-weight: 1000;
}

button1 span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

button1 svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

button1:hover {
  background: #000;
}

button1:hover .svg-wrapper {
  transform: scale(1.25);
  transition: 0.5s linear;
}

button1:hover svg {
  transform: translateX(1.2em) scale(1.1);
  fill: #fff;
}

button1:hover span {
  opacity: 0;
  transition: 0.5s linear;
}

button1:active {
  transform: scale(0.95);
}

/* Extra Small Screens */
@media (max-width: 1024px) {

  .cardsall {
    flex-direction: column; /* Stack cards vertically on small screens */
    gap: 2rem; /* Adjust gap between cards for better readability */
  }

  .card, .card1 {
    width: 90%;
    margin: 30px;  
    
    /*Make cards full width on small screens */
  }

  .content {
    padding: 24px; /* Adjust padding for smaller screens */
    font-size: 16px; /* Reduce font size for better readability */
  }
 
}

/* Medium Screens (Optional) */
@media (min-width: 768px) and (max-width: 992px) {
  /* Add your adjustments for medium screens here */
}

/* Large Screens (Optional) */
@media (min-width: 992px) {
  /* Add your adjustments for large screens here */
}
@media (min-width: 1024px) and (max-width: 1199px) {
  
  
}

 /* .bgcontainer{
  width: 100%;
  height: 40vh;
  


}

h1{
  margin-left: 36vw;
  font-size: 5rem;
} */


/* footer */






 /* background: rgb(233,239,255);
 background: radial-gradient(circle, rgba(233,239,255,1) 30%, rgba(197,206,254,1) 100%); */

 /* background: rgb(233,239,255);
background: -moz-radial-gradient(circle, rgba(233,239,255,1) 60%, rgba(197,206,254,1) 100%);
background: -webkit-radial-gradient(circle, rgba(233,239,255,1) 60%, rgba(197,206,254,1) 100%);
background: radial-gradient(circle, rgba(233,239,255,1) 60%, rgba(197,206,254,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e9efff",endColorstr="#c5cefe",GradientType=1); */
.centrebody{
  
  background-image: url("../public/assets/background.jpeg");
  z-index: -999;
}

.centre{
  font-size: 3.3rem;
  margin-top: -20px;
  color: black;
  font-family: gilroy-bold;

}

.centre:hover{
     font-size: 3.7rem;
     cursor: pointer;
     opacity: 0.6;
}

.para{
  font-family: gilroy-bold;

}
span{
  font-family: gilroy-bold;
}
@font-face{
  font-family: gilroy-semibolditalic;
  src: url(../public/Gilroy-SemiBoldItalic.ttf);
}

@font-face{
  font-family: gilroy-bold;
  src: url(../public/Gilroy-Bold.ttf);
}

@font-face{
  font-family: gilroy-medium;
  src: url(../public/Gilroy-Medium.ttf);
}

@media (min-width:1024px) and (max-width:1200px){
  

  #card1para{
    padding-right: 10vw;
  }
  
}
