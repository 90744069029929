@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.contactbody{
    

    background-image: url("../public/assets/background.jpeg");
   z-index: -999;
  
}

body {
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 1.5;
    animation: fadeIn 2s ease-in 1;
    
    /* background: linear-gradient(to center, #7091e6 60%, #ede8f5 40%); */
}

#maincentre{
    background-color: white;

}



.contact-bg {
    height: 32vh;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    text-align: center;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    letter-spacing: 0.01;
    animation: fadeIn 2s ease-in 1;
}

.contact-bg h3 {
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 50px;
}

.contact-bg h2 {
    font-size: 4.2rem;
    text-transform: uppercase;
    padding: 0.4rem 0;
    letter-spacing: 4px;
}

.line div {
    margin: 0 0.2rem;
}

.line div:nth-child(1),
.line div:nth-child(3) {
    height: 3px;
    width: 70px;
    background: #3d52a0;
    border-radius: 5px;
}

.line {
    display: flex;
    align-items: center;
}

.line div:nth-child(2) {
    width: 10px;
    height: 10px;
    background: #3d52a0;
    border-radius: 50%;
}

.text {
    font-weight: 300;
    opacity: 0.9;
}

.contact-bg .text {
    margin: 1.6rem 0;
}

.contact-body {
    max-width: 1320px;
    text-align: center;
    padding: 0 1rem;
}

.contact-info {
    margin: 2rem 0;
    text-align: center;
    padding: 2rem 0;
    animation: scaleUp 2s ease-in 1;
    display: grid;
    gap: 1rem;
}

.contact-info span {
    display: block;
}

.contact-info div {
    margin: 0.8rem 0;
    padding: 1rem;
}

.contact-info span .fas {
    font-size: 2rem;
    padding-bottom: 0.9rem;
    color: #3d52a0;
}

.contact-info div span:nth-child(2) {
    font-weight: 500;
    font-size: 1.1rem;
}

.contact-info .text {
    padding-top: 0.4rem;
}

.contact-form {
    padding: 2rem 0;
    border-top: 1px solid #c7c7c7;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-right: 5%;
    margin-left: 5%;
    border-radius: 40px;
    padding: 3%;
    box-shadow: #75757571 0px 10px 20px 0px;
    position: relative;
    height: auto;
    width: 85vw;
    animation: slideIn 2s ease-in 1;
}

.contact-form form {
    padding-bottom: 1rem;
}

.contact-form form input {
    width: 100%;
    margin-bottom: 25px;
    padding: 20px 22px;
    border-radius: 60px;
    border: 1px solid #adadadb0;
    box-shadow: #75757571 0px 10px 20px 0px;
}

.form-control {
    width: 100%;
    border: 1.5px solid #c7c7c7;
    border-radius: 5px;
    padding: 0.7rem;
    margin: 0.6rem 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    outline: 0;
}

.form-control:focus {
    box-shadow: 0 0 6px -3px rgba(48, 48, 48, 1);
}

.contact-form form div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.6rem;
}

.send-btn {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    color: #fff;
    background: #00ce45;
    border: none;
    border-radius: 5px;
    padding: 0.7rem 1.5rem;
    cursor: pointer;
    transition: all 0.4s ease;
}

.send-btn:hover {
    opacity: 0.8;
}

.contact-form > div img {
    padding-left: 10px;
    width: 85%;
}

.contact-form > div {
    margin: 0 auto;
    text-align: left;
}








@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-40%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes scaleUp {
    from {
        opacity: 0;
        transform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.popup {
    display: none;
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.popup-content {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 50px 50px;
    border: 1px solid #888;
    height: 50%;
    width: 80%;
    max-width: 300px;
    text-align: center;
    border-radius: 10px;
    position: relative;
    font-family: "Roboto Condensed",sans-serif;
    font-size: 110%;
}

.close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 20px;
}

.close-btn:hover,
.close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.photo img{
    height: 100%;
    width: 100%;
}

#first_name{
    width: 200%;
}

/* Responsive Design */
@media screen and (min-width: 768px){
    .contact-bg .text {
        width: 50%;
       
    }
        .contact-info{
        display: grid;
           grid-template-columns: repeat(2, 1fr);
        align-items: center;  
}

}

@media screen and (min-width: 992px){
    .contact-form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
    }
}


@media screen and (min-width: 1200px){
    .contact-info {
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
        margin-left: 5.5%;
    }

    .contact-form {
        margin-left: 7%;
        
    }

    /* .container{
        background-color: #FAFAFC;
      
    } */

       /* input{
        height: 30px;
       } */
    .form-control{
        height: 60px;
    }
   

   
}

/* @media screen and (max-width: 740px){
    .contact-bg .text {
        width: 50%;
       
    }

} */

.input.submit{
background:#001631;
box-shadow: #75757571 0px 10px 20px 0px;
color: white;
font-weight: bold;
}


/* CONTACT FOOTER*/

/* body {
    background: #fbfbfd;
} */


@font-face{
    font-family: gilroy-extrabold;
    src: url(../public/Gilroy-ExtraBold.ttf);
  }
  
h3{
    font-family: gilroy-extrabold;
  }

h2{
    font-family: gilroy-extrabold;

}